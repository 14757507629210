/**
 * Direttiva che costruisce la barra di ricerca
 */
import { Item, User } from "atfcore-commonclasses";

export interface IGenericSearchBarScope extends ng.IScope {
	chartOptions: any;
	itemType: any;
	hourValue: string;
	hasSuggested: boolean;
	globalApplicationData: any;
	bgacademyApplicationData: any;
	suggestedPerson: any;
	page: any;
	item: any;
	isItemOpen: boolean;
	percentageItem: boolean;
	suggestedFromAdmin: boolean;
	isFilterToolbarExpanded: boolean;
	expandFilterToolbar: Function;
	tabOneTitle: string;
	removeFilters: Function;
	hasTabsAtLeastOneFilter: Function;
	filters: any;
	selectedIndex: number;
	bienniumList: Array<any>;
	haveSameId: Function;
	getBienniumList: Function;
	onSelect: Function;
	tabs: any[];
	isUserList: boolean;
	onSubmit: Function;
	doSearch: Function;
	isGroupList: boolean;
}
angular.module('app').directive("genericSearchBar", ($window, $state, BgacademyApplicationData, GlobalApplicationData, $translate) => {
	return {
		restrict: 'AE',
		scope: {
			page: '=', // pagina corrente
			tabs: '=', // tab dinamiche da costruire
			toggleResultView: '=', // toggle risultati card/lista
			isCardResultView: '=', // controlla in quale modalità di visualizzazione sono 
			searchCourses: '=', // avvia la ricerca
			creditTypeList: '=', // lista di crediti disponibili
			courseTypeList: '=', // lista di corsi disponibili
			itemTypeList: '=', // lista delle tipologie di Item disponibili
			selectedIndex: '=?', // filtri della ricerca
			bienniumList: '=', // lista di bienni dell'iscrizione rui
			getBienniumList: '=', // recupera la lista dei bienni
			showTabOne: "=",
			tabChanged: '&',
			isGroupList: '=',
			isUserList: '='
		},
		link: link,
		templateUrl: 'app/shared/searchBar/genericSearchBar.html'
	};
	function link($scope: IGenericSearchBarScope, element: JQuery, attrs: any) {

		$scope.tabs = $scope.tabs || [];

		for (let i = 0; i < $scope.tabs.length; i++) {
			if (!$scope.tabs[i]['tabChanged']) {
				$scope.tabs[i]['tabChanged'] = (tab: any) => { }
			}
		}

		$scope.selectedIndex = $scope.selectedIndex || 0;

		// Collegamento all'oggetto principale
		$scope.bgacademyApplicationData = BgacademyApplicationData;

		// Titolo in traduzione delle quattro Tab nella barra dei filtri
		//$scope.tabOneTitle = $translate.instant("trainingPlan.tabs.title.ALL");

		// Controlla l'espansione della barra dei filtri
		$scope.isFilterToolbarExpanded = false;
		// Espande la barra dei filtri
		$scope.expandFilterToolbar = () => {
			$scope.isFilterToolbarExpanded = !$scope.isFilterToolbarExpanded;
		}

		$scope.doSearch = (tab: any) => {
			if ($scope.isGroupList) {
				return;
			} else {
				if ($scope.isUserList) {
					tab.search(true, true, false, true);
				} else {
					tab.search(null, null, true);
				}
			}
		}

		$scope.onSelect = (tab: any, index: any, fromSearchBarBtn?: boolean) => {
			$scope.selectedIndex = index;
			if ($scope.isUserList) {
				tab.tabChanged(tab, index, fromSearchBarBtn);
				if (fromSearchBarBtn) {
					$scope.doSearch(tab);
				}
			} else {
				if ($scope.isGroupList) {
					tab.search(null, null, true);
				} else {
					$scope.doSearch(tab);
				}
				tab.tabChanged(tab);
			}
		}

		$scope.onSubmit = (tab: any) => {
			if ($scope.isUserList) {
				tab.search(true, true, true);
			} else {
				tab.search(null, null, true);
			}
		}

		// Azzera i filtri
		$scope.removeFilters = () => {
			$scope.filters.searchedText = '';
			$scope.filters.biennium = $scope.bienniumList[0];
			$scope.filters.openedTab = 0;
		}

		// Verifica che ci sia almeno un filtro attivo
		$scope.hasTabsAtLeastOneFilter = () => {
			// TODO-ALLOY
            /*
			if (!$scope.haveSameId($scope.filters.creditType, $scope.creditTypeList[0]) ) {
                return true;
            }
			*/
			return false;
		}

		// Verifica se due oggetti sono uguali
		$scope.haveSameId = (obj1: any, obj2: any) => {
			if (obj1 && obj2) {
				return obj1.id === obj2.id;
			}
		}
	}

});